/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#homebanners{
  margin-top: 94px;
}


.service_icon_wrapper {
  display: inline-block;
}

.circle_background {
  width: 100px;
  height: 100px;
  background-color: #ccc; /* Replace with your desired background color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_icons {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;

}




.service_div {
  position: relative;
}

.service_div:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 100%;
  transform: translateY(50%);
  width: 1px;
  height: 80%;
  background-color: #000; /* Customize the line color here */
}

.service_div:nth-child(3n+3)::after {
  display: none;
}



